<template>
  <div>
    <v-container>
      <v-sheet
        color="nav_bg"
        class="pa-5 text-center rounded-xl"
      >
        <div class="text--disabled">
          <small>{{ $t('fen_yue') }}</small>
        </div>

        <div class="d-flex align-center justify-center text-h4 font-weight-medium my-3 primary--text">
          <img
            width="24"
            height="24"
            src="../assets/img/icons/coin.svg"
            class="mr-1"
          >
          {{ agentinfo.assets?.available }}
        </div>

        <v-btn
          v-if="loaded && agentinfo.pid==0"
          dark
          depressed
          rounded
          color="primary"
          class="px-10"
          to="Deposit_pix"
        >
          {{ $t('maifen') }}
        </v-btn>
        <template v-else>
          <v-dialog
            v-if="loaded"
            v-model="shangfendialog"
            width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                rounded
                small
                color="primary"
                class="mx-1 text-capitalize"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('shangfen') }}
              </v-btn>
            </template>

            <v-card>
              <div class="text-center pa-5">
                {{ $t('shangfen_tip') }}
              </div>

              <v-divider class="opacity-3" />

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="shangfendialog = false"
                >
                  {{ $t('zdl') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            v-if="loaded"
            dark
            depressed
            rounded
            small
            color="primary"
            class="mx-1 text-capitalize"
            to="Withdraw"
          >
            {{ $t('xiafen') }}
          </v-btn>
        </template>
      </v-sheet>

      <v-row
        dense
        class="my-4"
      >
        <v-col
          class="text-center"
        >
          <v-btn
            fab
            depressed
            dark
            color="light-blue"
            class="rounded-xl"
            @click="transfer"
          >
            <v-icon>
              mdi-send
            </v-icon>
          </v-btn>
          <div class="text--secondary text-body-2 mt-2">
            {{ $t('zhuanfen') }}
          </div>
        </v-col>
        <!--
        <v-col
          class="text-center"
        >
          <v-btn
            fab
            depressed
            dark
            color="orange"
            class="rounded-xl"
            @click="cashout"
          >
            <v-icon>
              mdi-database-arrow-down
            </v-icon>
          </v-btn>
          <div class="text--secondary text-body-2 mt-2">
            {{ $t('jianfen') }}
          </div>
        </v-col>
        -->
        <v-col
          class="text-center"
        >
          <v-btn
            fab
            depressed
            dark
            color="deep-purple"
            class="rounded-xl"
            to="Approval"
          >
            <v-badge
              :content="wait_count"
              :value="wait_count"
              color="red"
              label=""
            >
              <v-icon>
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
          <div class="text--secondary text-body-2 mt-2">
            {{ $t('daiban') }}
          </div>
        </v-col>
        <v-col
          class="text-center"
        >
          <v-btn
            fab
            depressed
            dark
            color="teal"
            class="rounded-xl"
            @click="adduser = !adduser"
          >
            <v-icon>
              mdi-account-plus
            </v-icon>
          </v-btn>
          <div class="text--secondary text-body-2 mt-2">
            {{ $t('xinzeng') }}
          </div>
        </v-col>
      </v-row>

      <div>
        <v-card
          flat
          class="rounded-xl my-3"
        >
          <v-list-item
            to="MyPlayer"
          >
            <v-list-item-avatar>
              <v-icon
                class="green rounded-lg"
                dark
              >
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('wanjia_wode') }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              <strong class="primary--text mr-1">{{ agentinfo.total_players }}</strong>
              {{ $t('ren') }}
            </v-list-item-subtitle>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-divider class="opacity-3" />
          <v-card-text>
            <v-row
              no-gutters
              class="text-center"
            >
              <v-col>
                <div class="text--disabled">
                  {{ $t('zhuanru') }}
                </div>
                <strong>
                  {{ agentinfo.total_player_cashout }}
                </strong>
              </v-col>
              <v-col>
                <div class="text--disabled">
                  {{ $t('zhuanchu') }}
                </div>
                <strong>
                  {{ agentinfo.total_player_topup }}
                </strong>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="opacity-3 mb-n2" />
          <v-card-text class="pb-2">
            <v-subheader>
              {{ $t('code') }}
              <v-spacer />
              <strong class="primary--text">{{ agentinfo.invite_code }}</strong>
              <v-btn
                icon
                @click="doCopy(agentinfo.invite_code)"
              >
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </v-subheader>
            <v-text-field
              filled
              rounded
              background-color="app_bg"
              hide-details="auto"
              append-icon="mdi-content-copy"
              readonly
              :value="invite_url"
              class="mb-3"
              @click:append="doCopy(invite_url)"
            />
          </v-card-text>
        </v-card>

        <v-card
          flat
          class="rounded-xl my-3"
        >
          <v-list-item
            to="MyAgent"
          >
            <v-list-item-avatar>
              <v-icon
                class="indigo rounded-lg"
                dark
              >
                mdi-account-tie
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('daili_wode') }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              <strong class="primary--text mr-1">{{ agentinfo.total_agents }}</strong>
              {{ $t('ren') }}
            </v-list-item-subtitle>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>

        <v-card
          flat
          class="rounded-xl"
        >
          <v-card-text class="px-0">
            <v-subheader>{{ $t('jilu_jinqi') }}</v-subheader>
            <v-list>
              <v-list-item
                v-for="(item, index) in last10loglist"
                :key="'l10'+index"
                two-line
                @click="showdetail(item)"
              >
                <v-list-item-avatar
                  v-if="item.type=='deposit'"
                  tile
                >
                  <v-icon
                    color="green"
                    class="green lighten-5 rounded-lg"
                  >
                    mdi-cash
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-avatar
                  v-if="item.type2=='receive'"
                  tile
                >
                  <v-icon
                    color="blue"
                    class="blue lighten-5 rounded-lg"
                  >
                    mdi-bank-transfer-in
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-avatar
                  v-if="item.type2=='send'"
                  tile
                >
                  <v-icon
                    color="orange"
                    class="orange lighten-5 rounded-lg"
                  >
                    mdi-bank-transfer-out
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-if="item.type=='deposit'">
                    {{ $t('buy') }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.type2=='receive'">
                    {{ $t('zhuanru') }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.type2=='send'">
                    {{ $t('zhuanchu') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <small class="mr-1">{{ item.created }}</small>
                    <v-chip
                      v-if="item.status==0"
                      x-small
                      color="lighten-5"
                    >
                      {{ $t('chulizhong') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==1"
                      x-small
                      color="green lighten-5"
                      text-color="green"
                    >
                      {{ $t('yiwancheng') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==-1"
                      x-small
                      color="red lighten-5"
                      text-color="red"
                    >
                      {{ $t('shibai') }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action
                  v-if="item.type=='deposit'"
                  class="text-right"
                >
                  <v-list-item-title>
                    <strong>+{{ item.remark_money }}</strong> {{ $store.state.agentinfo.currency }}
                  </v-list-item-title>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.type2=='receive'"
                  class="text-right"
                >
                  <v-list-item-title>
                    <strong>+{{ item.amount }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.member_name }}
                  </v-list-item-subtitle>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.type2=='send'"
                  class="text-right"
                >
                  <v-list-item-title>
                    <strong>-{{ item.amount }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.member_name }}
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div class="text-center">
              <v-btn
                outlined
                depressed
                small
                class="rounded-pill"
                color="primary"
                to="Record"
              >
                {{ $t('gengduo') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>

    <v-bottom-sheet
      v-model="adduser"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
          class="pt-2"
        >
          <v-btn
            icon
            @click="adduser = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="opacity-7">
            {{ $t('qingxuanze') }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-list class="transparent pb-6">
          <v-list-item
            to="AddPlayer"
          >
            <v-list-item-avatar>
              <v-icon
                class="green rounded-lg"
                dark
              >
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('wanjia_xinzeng') }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                mdi-plus
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            to="AddAgent"
          >
            <v-list-item-avatar>
              <v-icon
                class="indigo rounded-lg"
                dark
              >
                mdi-account-tie
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('daili_xinzeng') }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                mdi-plus
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="transfer_select"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
          class="pt-2"
        >
          <v-btn
            icon
            @click="transfer_select = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="opacity-7">
            {{ $t('qingxuanze') }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-list class="transparent pb-6">
          <v-list-item
            @click="recentplayers"
          >
            <v-list-item-avatar>
              <v-icon
                class="green rounded-lg"
                dark
              >
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('zhuanfen_to_wanjia') }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            @click="recentagents"
          >
            <v-list-item-avatar>
              <v-icon
                class="indigo rounded-lg"
                dark
              >
                mdi-account-tie
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('zhuanfen_to_daili') }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="Payment_Methods"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
          class="pt-2"
        >
          <v-btn
            icon
            @click="Payment_Methods = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="opacity-7">
            {{ $t('pay_fangshi') }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-list class="transparent pb-6">
          <v-list-item
            to="Deposit"
          >
            <v-list-item-avatar>
              <img src="../assets/img/icons/usdt.svg">
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('pay_usdt') }}</v-list-item-title>
            <v-list-item-action>USDT</v-list-item-action>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="agentinfo.currency=='BRL'"
            to="Deposit_pix"
          >
            <v-list-item-avatar>
              <img src="../assets/img/logos/pix-icon.png">
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('pay_pix') }}</v-list-item-title>
            <v-list-item-action>BRL</v-list-item-action>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="agents_list"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
          class="pt-2"
        >
          <v-btn
            icon
            @click="agents_list = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="opacity-7">
            {{ $t('daili_xuanze') }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <div class="pa-3">
          <v-text-field
            v-model="searchagent"
            filled
            rounded
            dense
            clearable
            no-data-text=""
            hide-details="auto"
            append-icon="mdi-magnify"
            :placeholder="$t('sousuo_tip')"
            @click:append="search('agent')"
          />
        </div>

        <v-list
          max-height="600"
          class="transparent pt-0 pb-6 overflow-auto"
        >
          <div>
            <v-subheader v-if="recentagentshow">
              {{ $t('jilu_jinqi') }}
            </v-subheader>
            <v-list-item
              v-for="(item, index) in recentagentlist"
              :key="'allist'+index"
              @click="showTrans('agent', item)"
            >
              <v-list-item-avatar>
                <v-img :src="require('../assets/img/avatar/'+item.avatar+'.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.username }}</v-list-item-title>
                <v-list-item-subtitle class="opacity-5">
                  UID: {{ item.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <strong>{{ item.available }}</strong>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="players_list"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
          class="pt-2"
        >
          <v-btn
            icon
            @click="players_list = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="opacity-7">
            {{ $t('wanjia_xuanze') }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <div class="pa-3">
          <v-text-field
            v-model="searchplayer"
            filled
            rounded
            dense
            clearable
            no-data-text=""
            hide-details="auto"
            append-icon="mdi-magnify"
            :placeholder="$t('sousuo_tip')"
            @click:append="search('player')"
          />
        </div>

        <v-list
          max-height="600"
          class="transparent pt-0 pb-6 overflow-auto"
        >
          <div>
            <v-subheader v-if="recentplayershow">
              {{ $t('jilu_jinqi') }}
            </v-subheader>
            <v-list-item
              v-for="(item, index) in recentplayerlist"
              :key="'pllist'+index"
              @click="transOrcash(item)"
            >
              <v-list-item-avatar>
                <v-img :src="require('../assets/img/avatar/'+item.avatar+'.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.username }}</v-list-item-title>
                <v-list-item-subtitle class="opacity-5">
                  UID: {{ item.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <strong>{{ item.available }}</strong>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <template v-if="showTransfer">
      <Transfer
        :to-role="transitem.role"
        :transitem="transitem"
        @close_transfer="close_transfer"
      />
    </template>

    <template v-if="showCashout">
      <Cashout
        :from-role="transitem.role"
        :transitem="transitem"
        @close_cashout="close_cashout"
      />
    </template>

    <template v-if="detail_deposit">
      <DetailDeposit
        :detail="deposit_detail_item"
        @close_detaildeposit="close_detaildeposit"
      />
    </template>

    <template v-if="detail_transfer">
      <DetailTransfer
        :detail="transfer_detail_item"
        @close_detailtransfer="close_detailtransfer"
      />
    </template>

    <template v-if="detail_cashout">
      <DetailCashout
        :detail="cashout_detail_item"
        @close_detailcashout="close_detailcashout"
      />
    </template>

    <template v-if="detail_receive">
      <DetailReceive
        :detail="receive_detail_item"
        @close_detailreceive="close_detailreceive"
      />
    </template>

    <template v-if="detail_send">
      <DetailSend
        :detail="send_detail_item"
        @close_detailsend="close_detailsend"
      />
    </template>
  </div>
</template>

<script>
import store from "../store/"
import Transfer from '@/views/pages/transfer.vue'
import Cashout from '@/views/pages/cashout.vue'
import DetailDeposit from '@/views/pages/detailDeposit.vue'
import DetailTransfer from '@/views/pages/detailTransfer.vue'
import DetailCashout from '@/views/pages/detailCashout.vue'
import DetailReceive from '@/views/pages/detailReceive.vue'
import DetailSend from '@/views/pages/detailSend.vue'
  export default {
    name: 'Home',
    components:{
      Transfer,
      Cashout,
      DetailDeposit,
      DetailTransfer,
      DetailCashout,
      DetailReceive,
      DetailSend
    },
    data: () => ({
      loaded: false,
      shangfendialog: false,
      adduser: false,
      transfer_select: false,
      Payment_Methods: false,
      detail_deposit: false,
      detail_transfer: false,
      detail_cashout: false,
      detail_receive: false,
      detail_send: false,
      transOrcashout: '',
      agents_list: false,
      players_list: false,
      showTransfer: false,
      showCashout: false,
      balancetimer: null,
      agentinfo: {},
      transitem: {},
      recentagentshow: true,
      recentplayershow: true,
      recentagentlist: [],
      recentplayerlist: [],
      searchagent: '',
      searchplayer: '',
      last10loglist: [],
      deposit_detail_item: {},
      transfer_detail_item: {},
      cashout_detail_item: {},
      receive_detail_item: {},
      send_detail_item: {},
      invite_url: '',
      wait_count: 0
    }),
    computed: {
      
    },
    watch: {
      agents_list: {
        handler(newVal, oldVal) {
          if(newVal==false){
            this.searchagent = ''
            this.recentagentshow = true
          }else{
            
          }
        }
      },
      players_list: {
        handler(newVal, oldVal) {
          if(newVal==false){
            this.searchplayer = ''
            this.recentplayershow = true
          }else{
            
          }
        }
      },
    },
    created() {

    },
    mounted() {
      if(this.getStorage('agent_id')){
        this.myinfo()
        this.balancetimer = setInterval(() => {
          this.myinfo()
        }, 60000)
        this.last10log()
        this.cashout_wait_count()
      }
    },
    beforeDestroy () {
      clearInterval(this.balancetimer)
    },
    methods: {
      myinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.myinfo(paramObj).then((response) => {
          if(response.code==200){
            this.agentinfo = response.data
            this.invite_url = 'https://yelobet.com/?code=' + this.agentinfo.invite_code
            store.commit('agentinfo', this.agentinfo)
            this.loaded = true
          }
        })
      },
      cashout_wait_count() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.cashout_wait_count(paramObj).then((response) => {
          if(response.code==200){
            this.wait_count = response.data.count
          }
        })
      },
      last10log() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.last10log(paramObj).then((response) => {
          if(response.code==200){
            this.last10loglist = response.data.data
          }
        })
      },
      recentagents() {
        this.agents_list = true
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          keywords: this.searchagent
        }
        this.$server.recentagents(paramObj).then((response) => {
          if(response.code==200){
            this.recentagentlist = response.data.list
          }
        })
      },
      recentplayers() {
        this.players_list = true
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          keywords: this.searchplayer
        }
        this.$server.recentplayers(paramObj).then((response) => {
          if(response.code==200){
            this.recentplayerlist = response.data.list
          }
        })
      },
      search(type) {
        if(type=='agent'){
          if(this.searchagent){
            this.recentagentshow = false
          }else{
            this.recentagentshow = true
          }
          this.recentagents()
        }
        if(type=='player'){
          if(this.searchplayer){
            this.recentplayershow = false
          }else{
            this.recentplayershow = true
          }
          this.recentplayers()
        }
      },
      transfer() {
        this.transfer_select = !this.transfer_select
        this.transOrcashout = 'transfer'
      },
      payment() {
        this.Payment_Methods = !this.Payment_Methods
      },
      cashout() {
        this.myplayers()
        this.transOrcashout = 'cashout'
      },
      transOrcash(item) {
        console.log(this.transOrcashout)
        if(this.transOrcashout=='transfer'){
          this.showTrans('player', item)
        }
        if(this.transOrcashout=='cashout'){
          this.showCash('player', item)
        }
      },
      showTrans(to_role, item) {
        this.transitem = item
        this.transitem.role = to_role
        this.showTransfer = true
      },
      showCash(to_role, item) {
        this.transitem = item
        this.transitem.role = to_role
        this.showCashout = true
      },
      close_transfer() {
        this.showTransfer = false
      },
      close_cashout() {
        this.showCashout = false
      },
      showdetail(item) {
        if(item.type=='deposit'){
          this.deposit_detail_item = item
          this.detail_deposit = true
        }
        //if(item.type=='topup'){
        //  this.transfer_detail_item = item
        //  this.detail_transfer = true
        //}
        //if(item.type=='cashout'){
        //  this.cashout_detail_item = item
        //  this.detail_cashout = true
        //}
        if(item.type2=='receive'){
          this.receive_detail_item = item
          this.detail_receive = true
        }
        if(item.type2=='send'){
          this.send_detail_item = item
          this.detail_send = true
        }
      },
      close_detaildeposit() {
        this.detail_deposit = false
      },
      close_detailtransfer() {
        this.detail_transfer = false
      },
      close_detailcashout() {
        this.detail_cashout = false
      },
      close_detailreceive() {
        this.detail_receive = false
      },
      close_detailsend() {
        this.detail_send = false
      },
      doCopy: function (text) {
        text = ''+text//转string
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    }
  }
</script>
